var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"text-right py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":_vm.$t('search business')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-flex',{staticClass:"py-2 text-right",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[(
            this.allowedBranches == 'unlimited' ||
            this.branches.length < this.allowedBranches
          )?_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":_vm.newBusiness}},[_vm._v(" "+_vm._s(_vm.$t("new business"))+" ")]):_vm._e()],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.businesses,"search":_vm.search},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"width":"5","indeterminate":"","color":_vm.$store.state.primaryColor}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.viewIndividual(item.id)}}},[_vm._v("visibility")])]}},{key:"item.verified",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","rounded":"","color":_vm.getColor(item.verified),"dark":""}},[_vm._v(" "+_vm._s(_vm.setStatus(item.verified))+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }