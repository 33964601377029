<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
          <v-text-field
            v-model="search"
            dense
            solo
            :placeholder="$t('search business')"
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
          <v-btn
            :color="$store.state.primaryColor"
            outlined
            small
            v-if="
              this.allowedBranches == 'unlimited' ||
              this.branches.length < this.allowedBranches
            "
            @click="newBusiness"
          >
            {{ $t("new business") }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="businesses"
            :search="search"
          >
            <template v-slot:[`item.logo`]="{ item }">
              <v-avatar size="30">
                <v-img :src="item.logo" style="cursor: pointer">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        width="5"
                        indeterminate
                        :color="$store.state.primaryColor"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                small
                left
                :color="$store.state.primaryColor"
                @click="viewIndividual(item.id)"
                >visibility</v-icon
              >
            </template>
            <template v-slot:[`item.verified`]="{ item }">
              <v-btn small text rounded :color="getColor(item.verified)" dark>
                {{ setStatus(item.verified) }}
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";

export default {
  data: () => ({
    loading: true,
    search: "",

    allowedBranches: "",
    businesses: [],
    licenses: [],
    branches: [],
    licenseDetails: [
      { title: "License Code", value: "" },
      { title: "License Package", value: "" },
      { title: "Maximum Number of Branches ", value: "" },
      { title: "Total Number of Users", value: "" },
      { title: "Storage capacity", value: "" },
      // { title:'Storage used',          -       value:'' },
      { title: "Expiry Date", value: "" },
      { title: "Days Left", value: 0 },
      { title: "Payment Status", value: "" },
    ],
  }),

  created() {
    this.getBusinesses();
  },

  computed: {
    selectedStore() {
      return this.$store.getters.getCurrentBusiness;
    },

    currentUser() {
      return this.$store.state.currentUser;
    },
    headers() {
      return [
        {
          text: this.$t("logo"),
          value: "logo",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("name"),
          value: "businessName",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("type"),
          value: "businessType",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("registered on"),
          value: "dateCreated",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("verification status"),
          value: "verified",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("view"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    this.checkLicense();
  },

  methods: {
    //fetch contents of users license
    checkLicense() {
      console.log("check license");
      this.allowedBranches = this.$store.state.allowedBranches;
      this.currentUser = this.$store.state.currentUser;
      //step step questioning and reflection
      this.licenses = [];
      db.collection("licenses")
        .where("ownerId", "==", this.currentUser)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            db.collection("packages")
              .doc(snaps.data().packageId)
              .get()
              .then((snap) => {
                this.licenses.push({
                  id: snaps.id,
                  ...snaps.data(),
                  ...snap.data(),
                });

                //commit number of allowed stores to store
                this.branches = this.licenses[0].description.branches;

                // this.$store.commit(
                //   "SET_NUMBER_OF_ALLOWED_STORES",
                //   this.licenses[0].description.branches
                // );

                //this.numberOfAllowedStores();
                this.getLicenseValue();
              });
          });
        });
    },
    //return number of allowed stores by this user
    //numberOfAllowedStores() {
    //this.branches = this.$store.state.branches;

    //console.log("this branches " + this.branches);
    //},
    getLicenseValue() {
      this.licenses.forEach((val) => {
        this.licenseDetails[0].value = val.id.toUpperCase();
        this.licenseDetails[1].value = val.name.toUpperCase();
        this.licenseDetails[2].value = val.description.branches.toUpperCase();
        this.licenseDetails[3].value = val.description.users.toUpperCase();
        this.licenseDetails[4].value = val.description.storage;
        this.licenseDetails[5].value = this.DateFormat(val.expiryDate);
        this.licenseDetails[6].value = val.expiryDate;
        //    this.DateDifference(val.expiryDate);
        this.licenseDetails[7].value = val.isValid;
      });
    },

    DateFormat(val) {
      return format(val.toDate(), "E dd-MMMM-yyyy hh:mm:ss");
    },

    MonthFormat(days) {
      if (days > 30) {
        var months = Math.floor(days / 31);
        var mrmd = days / 31 - Math.floor(months);
        var d = Math.floor(mrmd * 31);
        return months + " mths " + d + " d";
      } else {
        return days + " d";
      }
    },

    getBusinesses() {
      if (this.selectedStore) {
        db.collection("businesses")
          .doc(this.selectedStore)
          .get()
          .then((doc) => {
            this.businesses.push({
              id: doc.id,
              ...doc.data(),
            });
            this.loading = false;
          });
      } else {
        db.collection("businesses")
          .where("ownerId", "==", this.currentUser)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.businesses.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    setStatus(val) {
      if (val) {
        return "Verified";
      } else {
        return "Pending";
      }
    },
    getColor(val) {
      if (val) return "blue lighten-1";
      else return "red  lighten-1";
    },

    viewIndividual(busID) {
      this.$router.push({ name: "ViewStoreDetails", params: { id: busID } });
    },
    newBusiness() {
      this.$router.push({ name: "CreateStore" });
    },
  },
};
</script>

<style></style>
